import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';
import Postshare from '../Postshare/Postshare'
function Sharemodel({ modalopened, setmodalopened }) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal
        opened={opened || modalopened} // Combine local state and prop state
        onClose={() => {
          close();
          setmodalopened(false);
        }}
        // title="Authentication"
        size='55%'
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
          
        }}
      >
       <Postshare/>
      </Modal>

     
    </>
  );
}

export default Sharemodel;
