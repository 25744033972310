import React from 'react'
import './Infocard.css'
import {UilPen} from '@iconscout/react-unicons'
import { useState } from 'react'
import Profilemodel from '../Profilemodel/Profilemodel'
const Infocard = () => {
    const [modalopened, setmodalopened] = useState(false)
  return (
    <div className="infocard">
        <div className="infohead">
            <h4>Your Info</h4>
            <div>

            <UilPen onClick={()=>setmodalopened(true)} />
            <Profilemodel modalopened={modalopened} setmodalopened={setmodalopened} />
            </div>
        </div>
        <div className="info">
            <span>
                <strong>Status </strong>
            </span>
            <span>in Relationship</span>
        </div>
        <div className="info">
            <span>
                <strong>Lives in </strong>
            </span>
            <span>Delhi</span>
        </div>
        <div className="info">
            <span>
                <strong>Works at </strong>
            </span>
            <span>Twitter</span>
        </div>
        <button className='btn logout-btn'>Logout</button>
    </div>
  )
}

export default Infocard