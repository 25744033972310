import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';

function Profilemodel({ modalopened, setmodalopened }) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal
        opened={opened || modalopened} // Combine local state and prop state
        onClose={() => {
          close();
          setmodalopened(false);
        }}
        // title="Authentication"
        size='55%'
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
          
        }}
      >
        <form className='infoform' action="">
          <h3>Your Info</h3>
          <div>
            <input type="text" className='infoinput' name='firstname' placeholder='firstname' />
            <input type="text" className='infoinput' name='lastname' placeholder='lastname' />
          </div>
          <div>
            <input type="text" className='infoinput' name='worksat' placeholder='Works at' />
            
          </div>
          <div>
          <input type="text" className='infoinput' name='livesin' placeholder='livesin' />
            <input type="text" className='infoinput' name='country' placeholder='country' />
          </div>
          <div>
            <input type="text" className='infoinput'  placeholder='relationship status' />
          </div>
          <div>
            Profile Image
            <input type="file" name='profileImg' />
            Cover Image
            <input type="file"  name='coverImg' />
          </div>
          <button className='btn logout-btn'> Update</button>
        </form>
      </Modal>

     
    </>
  );
}

export default Profilemodel;
