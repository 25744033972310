import "./App.css"
import Profile from "./pages/Profile/Profile";
import Home from "./pages/home/Home";
import Auth from './pages/Auth/Auth'
function App() {
  return (
    <div className="App">
        <div className="blur" style={{top:'-10%',right:'6rem'}}></div>
        <div className="blur" style={{top:'36%',left:'-8rem'}}></div>
        {/* <Home/> */}
       < Profile/>
       {/* <Auth/> */}
    </div>
  );
}

export default App;
