export const Trenddata=[

    {
        name:"Minions",
        shares:97,
    },
    {
        name:"Avengers",
        shares:80.5,
    },
    {
        name:"Animal",
        shares:79,
    },
    {
        name:"React",
        shares:89,
    },
    {
        name:"X",
        shares:97,
    },
    {
        name:"Detsvo",
        shares:97,
    }
]