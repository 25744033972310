import postPic1 from '../img/postpic1.jpg'
import postPic2 from '../img/postpic2.jpg'

import postPic3 from '../img/image3.jpeg'

export const PostsData=[
    {
        img:postPic1,
        name:'Tzuyu',
        desc:'Happy new year all friends!#2023',
        likes:2300,
        liked:true

    },
    {
        img:postPic2,
        name:'maryam',
        desc:'party time :)',
        likes:2300,
        liked:false
    },
    {
        img:postPic3,
        name:'selena gomez',
        desc:'at Archery festival',
        likes:800,
        liked:true
    }
]