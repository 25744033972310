import React from 'react'
import './Postsside.css'
import Postshare from '../Postshare/Postshare'
import Posts from '../Posts/Posts'
const Postsside = () => {
  return (
    <div className="postside">
        <Postshare/>
        <Posts/>
    </div>
    

  )
}

export default Postsside