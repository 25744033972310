import React from 'react'
import './Rightside.css'
import home from '../../img/home.png'
import Noti from '../../img/noti.png'
import comment from '../../img/comment.png'
import {UilSetting} from '@iconscout/react-unicons'
import Trendcart from '../Trendcart/Trendcart'
import { useState } from 'react'
import Sharemodel from '../sharemodel/Sharemodel'
const Rightside = () => {
  
  const [modalopened, setmodalopened] = useState(false)
  return (
    <div className="rightside">
        <div className="nav-icon">
            <img src={home} alt="" />
            <UilSetting style={{width:'2.5rem',height:'40px'}} /> 
            <img src={Noti} alt="" />
            <img src={comment} alt="" />
        </div>
        <Trendcart/>
        <button className='btn r-btn' onClick={()=>setmodalopened(true)} >
            Share
        </button>
         
            <Sharemodel modalopened={modalopened} setmodalopened={setmodalopened} />
    </div>
  )
}

export default Rightside