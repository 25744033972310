import React from 'react'
import './Profileleft.css'
import '../../components/logosearch/Logosearch'
import Infocard from '../Infocard/Infocard'
import Followerscard from '../../components/followerscard/Followerscard'
import Logosearch from '../../components/logosearch/Logosearch'
// import Profilecard from '../../components/profilecard/Profilecard'
const Profileleft = () => {
  return (
    <div className="profileleft">
        <Logosearch/>
        <Infocard/>
        <Followerscard/>
        {/* <Profilecard/> */}
    </div>
  )
}

export default Profileleft