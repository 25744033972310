import React, { useRef, useState } from 'react'
import porfileImage from '../../img/profileImg.jpg'
import './Postshare.css'

import {UilScenery} from '@iconscout/react-unicons'
import {UilPlayCircle} from '@iconscout/react-unicons'
import {UilLocationPoint} from '@iconscout/react-unicons'
import {UilSchedule} from '@iconscout/react-unicons'
import {UilTimes} from '@iconscout/react-unicons'

const Postshare = () => {
    const [image, setimage] = useState(null)
    const imageref=useRef()

    const onimagechange=(event)=>{
        if(event.target.files && event.target.files[0]){
            let img=event.target.files[0];
            setimage({
                image:URL.createObjectURL(img)
            })
        }

    }
  return (
    <div className="postshare">
        <img src={porfileImage} alt="" />
    
        <div>
            <input type="text" placeholder='Whats Happening' />
        <div className="postoptions">
            <div onClick={()=>imageref.current.click()} className='option' style={{color:'var(--photo'}}>
                <UilScenery/>
                Photo
            </div>
            <div className='option' style={{color:'var(--video'}}>
                <UilPlayCircle/>
                Video
            </div>
            <div className='option' style={{color:'var(--location'}}>
                <UilLocationPoint/>
                Location
            </div>
            <div className='option' style={{color:'var(--shedule'}}>
                <UilSchedule/>
                Schedule
            </div>
            <button className='btn ps-btn'>Share</button>
            <div style={{display:"none"}}>
                <input type="file" name="myImage"  ref={imageref} onChange={onimagechange} />
            </div>
        </div>
        {image && (
            <div className="previewimage">
                <UilTimes onClick={()=>setimage(null)} />
                <img src={image.image} alt="" />
            </div>
        )}
        </div>

    </div>
  )
}

export default Postshare