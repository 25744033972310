import React from 'react'
import './Followerscard.css'
import { Followers } from '../../Data/Followersdata'
const Followerscard = () => {
  return (
    <div className="followerscard">
        <h3>who is following you</h3>
        {Followers.map((follower,id)=>{
            return(
                <div className="follower">
                    <div >
                        <img src={follower.img} className='followerimg' alt="" />
                        <div className="name">
                            <span>{follower.name}</span>
                            <span>@{follower.username}</span>
                        </div>
                    </div>

                    <button className='btn follow-button'>
                        Follow
                    </button>
                </div>
            )
        })}
    </div>
  )
}

export default Followerscard