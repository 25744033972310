import React from 'react'
import './Post.css'
import Comment from '../../img/comment.png'
import Share from '../../img/share.png'
import Heart from '../../img/like.png'
import Notlike from '../../img/notlike.png'
const Post = ({data}) => {
  console.log(data.desc)
  return (
    <div className="post">
      
      <img src={data.img} alt="" />
      <div className="postreact">

        <img src={data.liked?Heart:Notlike} alt="" />
        <img src={Comment} alt="" />
        <img src={Share} alt="" />
      </div>
      <span style={{color:"var(--gray"}}>{data.likes} likes</span>
      <div className="detail">
        <span><strong>{data.name}</strong></span>
        <span> {data.desc}</span>
      </div>
    </div>
    
  )
}

export default Post