import React from 'react'
import './Profile.css'
import Profileleft from '../../Profilecomp/Profileleft/Profileleft'
// import Infocard from '../../Profilecomp/Infocard/Infocard'
import Profilecard from '../../components/profilecard/Profilecard'
import Postsside from '../../components/Postsside/Postsside'
import Rightside from '../../components/Rightside/Rightside'
const Profile = () => {
  return (
    <div className="profile">
        <Profileleft/>
        <div className="profile-center">
          <Profilecard/>
          <Postsside/>
        </div>
        <Rightside/>
    </div>
  )
}

export default Profile