import React from 'react'
import './Profilecard.css'
import Cover from '../../img/cover.jpg'
import Profileimg from '../../img/profileImg.jpg'
const Profilecard = () => {
  const ProfilePage=true
  return (

    <div className="profilecard">
        <div className="profileimages">
            <img src={Cover} alt="" />
            <img src={Profileimg} alt="" />
        </div>
        <div className="profilename">
          <span>Zendya mj</span>
          <span>senior ui/ux designer</span>
        </div>
        <div className="followstatus">
          <hr />
          <div >
          <div className="follow">
            <span>6,890</span>
            <span>Followings</span>
          </div>
          <div className="vl"></div>
          <div className="follow">
            <span>1</span>
            <span>Followers</span>
          </div>
          {ProfilePage && (
            <>
            <div className="vl">

            </div>
            <div className="follow">
              <span>3</span>
              <span>Posts</span>
            </div>
            </>
          )}
          </div>
          <hr />
        </div>
        {ProfilePage?"":(

        <span>My Profile</span>
        )}
    </div>
  )
}

export default Profilecard