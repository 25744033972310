import React from 'react'
import './Trendcart.css'
import { Trenddata } from '../../Data/Trenddata'
const Trendcart = () => {
  return (
    <div className="trendcart">
        <h3>Trends for you</h3>
        {Trenddata.map((trend)=>{
            return (
                <div className="trend">
                    <span>#{trend.name}</span>
                    <span>{trend.shares}k shares</span>
                </div>
            )
        })}

    </div>
  )
}

export default Trendcart